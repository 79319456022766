import React from "react";
import { Link } from "react-router-dom";
import style from "./css/Homepage.module.scss";
import CharacteristicsItem from "../Components/CharacteristicsItem";

const Homepage = () => {
  return (
    <div>
      <nav className={`${style.o_nav} ${style.o_sticky}`} /* ref={navRef} */>
        <img src="/img/WEB_01-LOGO.png" alt="proteccion en linea" />
        <ul className={style.o_nav_options}>
          <a className={style.anchor} href="#caracteristicas">
            <li>Características</li>
          </a>
          <a href="#beneficios">
            <li>Beneficios</li>
          </a>
          <a href="#instalacion">
            <li>Instalación</li>
          </a>
        </ul>
        <Link to="/auth/login">
          <button type="button" className={style.o_login_btn}>
            Ingreso Web
          </button>
        </Link>
      </nav>

      <div
        className={`${style.o_header_container} p-5 text-center bg-image`}
        style={{
          height: "672px",
          marginTop: "123px",
        }}
      >
        <div className={style.o_mask_container}>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-white">
              <h1
                className="mb-3"
                style={{ fontSize: "4rem", fontWeight: "bold" }}
              >
                TOMA EL <strong style={{ color: "#f19e32" }}>CONTROL</strong>
                <br />
                DE TU SEGURIDAD
              </h1>
              <h4 className="mb-3">¡Descarga Protección en linea ya!</h4>
              <a
                href="https://play.google.com/store/apps/details?id=seguridadomega.com.co.pel_app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/WEB_06-LOGO1.png" alt="" />
              </a>
              <a
                href="https://apps.apple.com/co/app/protecci%C3%B3n-en-l%C3%ADnea/id1563992828"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/WEB_06-LOGO2.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={style.o_characteristics_container}>
        <img
          src="/img/WEB_03-FONDO.png"
          alt=""
          className={style.o_characteristics_left_image}
        />
        <img
          src="/img/WEB_03-GRAFICO.png"
          alt=""
          className={style.o_characteristics_right_image}
        />
        <h2 id="caracteristicas">CARACTERISTICAS PRINCIPALES</h2>
        <h1 className="mb-15">
          Funcionalidades que interactúan entre sí <br /> con un alto grado de
          seguridad
        </h1>
        <div className={style.o_characteristics_steps}>
          <CharacteristicsItem
            title="Control de visitantes"
            description="Registro y verificación visual del personal visitante que ingresa a las instalaciones con Protección en Linea, además de notificación inmediata en tiempo real"
            icon="/img/WEB_03-ICONO1.png"
          />
          <CharacteristicsItem
            title="Correspondencia y mensajería"
            description="Control, registro de mensajería, mensajes masivos del administrador a los residentes y envío de notificaciones al residente."
            icon="/img/WEB_03-ICONO2.png"
          />
          <CharacteristicsItem
            title="Control de ingreso de vehículos y zona de parqueo"
            description="Permite registrar y verificar todos los vehículos autorizados para ingresar a las instalaciones y sus áreas de parqueo."
            icon="/img/WEB_03-ICONO4.png"
          />
          <CharacteristicsItem
            title="Minutas electrónicas"
            description="Permite el registro, trazabilidad y envío instantáneo de las novedades que se presenten en las instalaciones."
            icon="/img/WEB_03-ICONO3.png"
          />
        </div>
      </div>

      <div className={style.o_benefits_container}>
        <div className="mt-15">
          <h2 id="beneficios">BENEFICIOS</h2>
          <h1 className="mb-15">
            Una herramienta que nos hace la vida más facil
          </h1>
        </div>
        <div className={style.o_benefits}>
          <div className={style.o_benefits_steps}>
            <CharacteristicsItem
              title="Tranquilidad"
              description="Al estar notificados inmediaatamente de los eventos de 
            acceso y/o administrativos de su residencia o empresa"
              icon="/img/WEB_04-ICONO1.png"
              type="benefits"
            />
            <CharacteristicsItem
              title="Calidad"
              description="Del servicio de seguridad privada al sistematizar sus 
            procesos."
              icon="/img/WEB_04-ICONO2.png"
              type="benefits"
            />
            <CharacteristicsItem
              title="Análisis"
              description="De riesgos permite la optimización de la inversión en 
            recursos para la seguridad."
              icon="/img/WEB_04-ICONO3.png"
              type="benefits"
            />
            <CharacteristicsItem
              title="Respaldo"
              description="De Seguridad Omega Ltda."
              icon="/img/WEB_04-ICONO4.png"
              type="benefits"
            />
          </div>
          <img
            src="/img/WEB_04-GRAFICO.png"
            alt=""
            className={style.o_mobile_image}
          />
        </div>
      </div>

      <div className={style.o_characteristics_container} style={{ zIndex: 0 }}>
        <img
          src="/img/WEB_03-FONDO.png"
          alt=""
          className={style.o_characteristics_left_image}
        />

        <h1 className="mb-15" id="instalacion">
          3 pasos para instalar. <br /> Fácil y rápido.
        </h1>

        <div className={style.o_install_steps}>
          <div>
            <img
              src="/img/WEB_05-GRAFICO1.png"
              alt="Paso 1"
              className="mb-15"
            />
            <CharacteristicsItem
              description="Descarga la app en la Play store o en la App store."
              icon="/img/WEB_05-ICONO1.png"
              type="benefits"
            />

            <div className="mb-10 mt-10">
              <a
                href="https://play.google.com/store/apps/details?id=seguridadomega.com.co.pel_app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/WEB_06-LOGO1.png" alt="" />
              </a>
              <a
                href="https://apps.apple.com/co/app/protecci%C3%B3n-en-l%C3%ADnea/id1563992828"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/WEB_06-LOGO2.png" alt="" />
              </a>
            </div>
          </div>
          <div>
            <img
              src="/img/WEB_05-GRAFICO2.png"
              alt="Paso 2"
              className="mb-15"
            />
            <CharacteristicsItem
              description="Registra tus datos."
              icon="/img/WEB_05-ICONO2.png"
              type="benefits"
            />
          </div>
          <div>
            <img
              src="/img/WEB_05-GRAFICO3.png"
              alt="Paso 3"
              className="mb-15"
            />
            <CharacteristicsItem
              description="¡Listo! toma el control de tu seguridad."
              icon="/img/WEB_05-ICONO3.png"
              type="benefits"
            />
          </div>
        </div>
      </div>
      <footer>
        <div className={style.o_top_block}>
          <h1>
            ¿Estas listo para empezar?
            <br />
            ¡Descarga la app ya!
          </h1>
          <div className={style.o_top_block_download}>
            <a
              href="https://play.google.com/store/apps/details?id=seguridadomega.com.co.pel_app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/img/WEB_06-LOGO1.png" alt="" />
            </a>
            <a
              href="https://apps.apple.com/co/app/protecci%C3%B3n-en-l%C3%ADnea/id1563992828"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/img/WEB_06-LOGO2.png" alt="" />
            </a>
          </div>
        </div>

        <div className={style.o_bottom_block}>
          <div>
            <h4>Protección en línea Todos los derechos reservados</h4>
            <h5>Politica de Tratamiento de Datos Personales</h5>
          </div>
          <div className={style.o_top_block_download}>
            <img src="/img/WEB_07-LOGO1.png" alt="" />
            <img src="/img/WEB_07-LOGO2.png" alt="" />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Homepage;
